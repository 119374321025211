import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const NewsForm = props => {
  const { layout, form } = props;

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        postcode: '',
        phone: '',
        date: '',
        persons: '',
        email: '',
        message: '',
        layout,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Ungültige E-Mail Adresse!')
          .required('E-Mail ist ein Pflichtfeld!')
          .trim(),
        date: Yup.date('Ungültiges Datum!').required('Wunschtermin ist ein Pflichtfeld!'),
        message: Yup.string().trim(),
      })}
      onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        fetch('https://mailproxy.projects.localhost.at/index.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            mailId: 'komm_in_die_variowelt',
            firstName: values.firstName,
            lastName: values.lastName,
            street: values.street,
            city: values.city,
            postcode: values.postcode,
            phone: values.phone,
            date: values.date,
            persons: values.persons,
            email: values.email,
            message: values.message,
          }),
        })
          .then(() => {
            setSubmitting(false);
            resetForm({
              firstName: '',
              lastName: '',
              street: '',
              city: '',
              postcode: '',
              phone: '',
              date: '',
              persons: '',
              email: '',
              message: '',
              layout,
            });
            setStatus('submitted');
          })
          .catch(error => {
            console.error(error);
            setSubmitting(false);
            setStatus('error');
          });
      }}
      render={form}
    />
  );
};

export default NewsForm;
