import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import fecha from 'fecha';

import * as style from './news.module.css';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';
import NewsForm from '@mangoart/gatsby-ui/components/ezagrar/NewsForm';
import NewsItemForm from '@mangoart/gatsby-ui/components/ezagrar/NewsItemForm';
import NewsItemImages from '@mangoart/gatsby-ui/components/ezagrar/NewsItemImages';
import DefaultLayout from '../layout/DefaultLayout';

NewsTemplate.propTypes = {
  pathContext: PropTypes.shape().isRequired,
};

function NewsTemplate(props) {
  const { data } = props;
  const { title, teaser, text, date, images, videoUrl } = data.item;

  const modifiedText = text.replace(/\/storage\//g, 'https://fendt.cms.mangomedia.at/storage/');

  const icon = 'news';
  const backToListLink = '/news';
  const pageTitle = 'News';
  return (
    <DefaultLayout>
      <article className="defaultWidth">
        <PageTitle>
          <Icon icon={icon} /> {pageTitle}
        </PageTitle>
        <Helmet>
          <title>{`${title} | ${pageTitle}`}</title>
          <meta name="description" content={teaser} />
        </Helmet>
        <div className={style.news}>
          <div className={style.right}>
            <h3 style={{ textAlign: 'left', lineHeight: '1.5em' }}>{title}</h3>
            <h4>{fecha.format(new Date(date), 'D.M.YYYY')}</h4>
            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: modifiedText }} />
            {videoUrl && (
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingBottom: '56.25%',
                  marginTop: '2.5em',
                }}
              >
                <iframe
                  title={data.item.id}
                  style={{ position: 'absolute', left: 0, top: 0 }}
                  src={videoUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="auto"
                  allowFullScreen="allowfullscreen"
                />
              </div>
            )}
            {/*{downloads && downloads.length > 0 && (*/}
            {/*  <>*/}
            {/*    <h4*/}
            {/*      style={{*/}
            {/*        textAlign: 'left',*/}
            {/*        fontSize: '1.3em',*/}
            {/*        margin: '1.5em 0 0.5em 0',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Downloads*/}
            {/*    </h4>*/}
            {/*    <p*/}
            {/*      style={{*/}
            {/*        textAlign: 'left',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {downloads.map(({ file, name }) => (*/}
            {/*        <a href={file.publicURL}>{name}</a>*/}
            {/*      ))}*/}
            {/*    </p>*/}
            {/*  </>*/}
            {/*)}*/}
            {title.toLocaleLowerCase() === 'komm in die variowelt!' && (
              <div className={style.marginTop}>
                <NewsForm form={NewsItemForm} />
              </div>
            )}
            <div className={style.allNewsItemsLink}>
              <Link to={backToListLink}>Zurück zur Liste</Link>
            </div>
          </div>
          <div className={style.left}>
            <NewsItemImages
              images={images.map(({ image }) => {
                return {
                  altText: '',
                  image: getImage(image),
                };
              })}
            />
          </div>
        </div>
      </article>
    </DefaultLayout>
  );
}

export default NewsTemplate;

export const query = graphql`
  query ($id: String) {
    item: cockpitNews(id: { eq: $id }) {
      id
      title
      teaser
      text
      date
      expirationdate
      images {
        image {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
      videoUrl
      downloads {
        name
        file {
          id
          publicURL
        }
      }
    }
  }
`;
