import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './LightBoxGallery.module.css';

export default class LightBoxGallery extends React.Component {
  static propTypes = {
    listOfImageURLs: PropTypes.array.isRequired,
    showTiles: PropTypes.bool,
    activeImageIndex: PropTypes.number,
  };

  static defaultProps = {
    showTiles: true,
  };

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      activeImageIndex: props.activeImageIndex ? props.activeImageIndex : 0,
    };
    this.listOfImageURLs = this.props.listOfImageURLs;
  }

  setActiveImage = index => {
    this.setState({
      activeImageIndex: index,
    });
  };

  getGalleryItems = () => {
    return this.listOfImageURLs.map((elem, index) => {
      return (
        <span key={'ListView_Image_' + index} onClick={() => this.setActiveImage(index)}>
          {this.getOneGalleryItem(index)}
        </span>
      );
    });
  };

  getOneGalleryItem = index => {
    return <img src={this.listOfImageURLs[index]} alt={this.listOfImageURLs[index]} />;
  };

  goLeft = () => {
    this.setActiveImage(
      this.state.activeImageIndex === 0 ? this.listOfImageURLs.length - 1 : this.state.activeImageIndex - 1,
    );
  };

  goRight = () => {
    this.setActiveImage(
      this.state.activeImageIndex === this.listOfImageURLs.length - 1 ? 0 : this.state.activeImageIndex + 1,
    );
  };

  render() {
    if (!this.listOfImageURLs) {
      return null;
    }
    const showArrows = this.listOfImageURLs.length > 1;
    return (
      <div className={styles.LightBoxGallery1}>
        <div className={styles.fullWidthContainer}>
          <div className={styles.imageInViewContainer}>
            <img src={this.listOfImageURLs[this.state.activeImageIndex]} className={styles.imageInView} />
          </div>
          {showArrows && (
            <div className={styles.leftArrow} onClick={this.goLeft}>
              {this.getDefaultArrow()}
            </div>
          )}
          {showArrows && (
            <div className={styles.rightArrow} onClick={this.goRight}>
              {this.getDefaultArrow('right')}
            </div>
          )}
        </div>
        {this.props.showTiles && <div className={styles.galleryItems}>{this.getGalleryItems()}</div>}
      </div>
    );
  }
  getDefaultArrow = (direction = 'left') => {
    let rotation = 'rotate(0)';
    switch (direction) {
      case 'left':
        break;
      case 'right':
        rotation = 'rotate(180 8.5 16)';
        break;
      case 'up':
        rotation = 'rotate(90 8.5 16)';
        break;
      case 'down':
        rotation = 'rotate(270 8.5 16)';
        break;
    }
    return (
      <svg viewBox="0 0 17 32">
        <path
          transform={rotation}
          d="M14.897 31.669c0.221 0.221 0.497 0.331 0.8 0.331s0.579-0.11 0.8-0.331c0.441-0.441 0.441-1.159 0-1.6l-14.069-14.069 14.069-14.069c0.441-0.441 0.441-1.159 0-1.6s-1.159-0.441-1.6 0l-14.897 14.869c-0.441 0.441-0.441 1.159 0 1.6l14.897 14.869z"
        />
      </svg>
    );
  };
}
