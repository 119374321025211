import React from 'react';
import classnames from 'classnames';
import * as styles from './NewsItemForm.module.css';
import { Icon } from './Icon';

const submitFeedback = status => {
  if (status === 'submitted') {
    return (
      <div className={classnames(styles.formControl, styles.formFeedback)}>
        Ihre Anfrage wurde erfolgreich abgeschickt!
      </div>
    );
  }
  if (status === 'error') {
    return (
      <div className={classnames(styles.formControl, styles.formFeedback, styles.error)}>
        Es kam leider zu einem Fehler beim Absenden des Formulars!
      </div>
    );
  }
  return null;
};

function NewsItemForm(props) {
  const {
    values,
    touched,
    errors,
    status,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = props;
  return (
    <div>
      <form onSubmit={handleSubmit} className={classnames(styles.NewsItemForm, styles[values.layout] || styles.small)}>
        <div className={styles.formControl}>
          <label>Name</label>
          <div className={styles.row}>
            <input
              id="firstName"
              placeholder="Vorname"
              aria-label="Vorname"
              aria-required="true"
              type="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
            />
            <input
              id="lastName"
              placeholder="Nachname"
              aria-label="Nachname"
              type="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
            />
          </div>
          <label>Anschrift</label>
          <div className={styles.column}>
            <input
              id="street"
              placeholder="Straße, Nr."
              aria-label="Straße, Nr."
              type="text"
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className={styles.row}>
              <input
                id="city"
                placeholder="Ort"
                aria-label="Ort"
                type="text"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <input
                id="postcode"
                placeholder="PLZ"
                aria-label="PLZ"
                type="text"
                value={values.postcode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <label>Telefon</label>
          <input
            id="phone"
            placeholder="Telefon"
            aria-label="Telefon"
            type="tel"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={styles.row}>
            <div className={styles.column}>
              <label>Wunschtermin</label>
              <input
                id="date"
                placeholder="Wunschtermin"
                aria-label="Wunschtermin"
                type="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.date && touched.date ? 'date-input error' : 'date-input'}
              />
            </div>
            <div className={styles.column}>
              <label>Anzahl Personen</label>
              <input
                id="persons"
                placeholder="Anzahl Personen"
                aria-label="Anzahl Personen"
                type="number"
                value={values.persons}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <label>E-Mail</label>
          <input
            id="email"
            placeholder="Ihre E-Mail Adresse"
            aria-label="Ihre E-Mail Adresse"
            aria-required="true"
            autoComplete="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
          />
          {values.layout === 'wide' && submitFeedback(status)}
        </div>
        <div className={styles.formControl}>
          <label>Schreibe uns gleich vorab dein Anliegen</label>
          <textarea
            id={'message'}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message && touched.message ? 'text-input error' : 'text-input'}
          />
          <button type="submit" disabled={isSubmitting}>
            <Icon icon={'arrow'} />
            Jetzt anmelden
          </button>
        </div>
        {values.layout !== 'wide' && submitFeedback(status)}
      </form>
    </div>
  );
}

export default NewsItemForm;
